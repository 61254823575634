/* eslint-disable jsx-a11y/media-has-caption, react/no-danger, jsx-a11y/no-noninteractive-element-interactions */
// @flow
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Grid, Box, Button } from '@material-ui/core';
import cx from 'classnames';
import { SITE_METADATA } from '../constants';
import styles from '../styles/pages/campaign.module.scss';
import Layout from '../components/Layout/Layout';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { TopBanner, PaginationColor, CampaignItem } from '../components';
import banner from '../images/hero-campaign.png';
import bannerSp from '../images/hero-campaign-sp.png';
import { fetchCampaigns } from '../redux/campaign';

const ITEM_PER_PAGE = 6;
const ITEM_PER_PAGE_SP = 3;

const getSortCampaign = (campaigns) =>
  campaigns
    .filter((item) => {
      // Remove item doesn't have title
      if (!item.title) {
        return false;
      }

      // Keep item without end date - means still occurring
      if (!item.endDate) {
        return true;
      }

      // Keep item starts within 12 months
      const startDate = new Date(item.startDate);
      const today = new Date();

      if (startDate.getFullYear() === today.getFullYear() - 1) {
        return startDate.getMonth() >= today.getMonth();
      }

      return startDate.getFullYear() >= today.getFullYear() - 1;
    })
    .sort((item1, item2) => {
      // Sort campaign without end dates first
      if (!item1.endDate) {
        return -1;
      }
      // Sort campaign not expired first

      const isExpired =
        item1.endDate && new Date(item1.endDate).getTime() < Date.now();

      if (!isExpired) {
        return -1;
      }

      if (!item2.endDate) {
        return 1;
      }

      // Sort campaign based on start dates first
      return (
        new Date(item2.startDate).getTime() -
        new Date(item1.startDate).getTime()
      );
    });

const CampaignPage = ({ location, campaigns, getCampaigns }) => {
  const [page, setPage] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentItemsSp, setCurrentItemsSp] = useState([]);
  const onGoingCampaigns = useMemo(() => getSortCampaign(campaigns), [
    campaigns,
  ]);

  useEffect(() => {
    getCampaigns();
  }, []);

  useEffect(() => {
    const from = page * ITEM_PER_PAGE;
    const to = from + ITEM_PER_PAGE;
    const fromSp = page * ITEM_PER_PAGE_SP;
    const toSp = from + ITEM_PER_PAGE_SP;

    setCurrentItems(onGoingCampaigns.slice(from, to));
    setCurrentItemsSp(onGoingCampaigns.slice(fromSp, toSp));
  }, [page, campaigns]);

  const handleLoadMoreClick = () => setPage((p) => p + 1);

  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.campaign.title,
        description: SITE_METADATA.campaign.description,
      }}
    >
      <TopBanner
        bgImg={banner}
        textAlign="center"
        className={cx(styles.pc, styles.banner)}
      >
        <span>キャンペーン</span>
      </TopBanner>
      <TopBanner
        bgImg={bannerSp}
        textAlign="center"
        className={cx(styles.sp, styles.banner)}
      >
        <span>キャンペーン</span>
      </TopBanner>
      <Breadcrumb location={location} />
      <section className={styles.campaignItems}>
        <Grid container spacing={3} className={styles.pc}>
          {currentItems.map((item) => (
            <Grid item xs={12} md={4} lg={4} className={styles.item}>
              <CampaignItem item={item} />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={3} className={styles.sp}>
          {currentItemsSp.map((item) => (
            <Grid item xs={12} md={4} lg={4} className={styles.item}>
              <CampaignItem item={item} />
            </Grid>
          ))}
        </Grid>
        {onGoingCampaigns.length > ITEM_PER_PAGE && (
          <PaginationColor
            pageCount={Math.ceil(onGoingCampaigns.length / ITEM_PER_PAGE)}
            page={page}
            gotoPage={(selected) => setPage(selected)}
            className={styles.pagination}
          />
        )}
      </section>

      {currentItems.length < onGoingCampaigns.length && (
        <Box textAlign="center" className={styles.loadMoreBtnContainer}>
          <Button
            className={styles.loadMoreBtn}
            variant="outlined"
            color="primary"
            onClick={handleLoadMoreClick}
          >
            もっと見る
          </Button>
        </Box>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  campaigns: state.campaign.data || [],
});

const mapDispatchToProps = (dispatch) => ({
  getCampaigns: () => dispatch(fetchCampaigns()),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CampaignPage);
